<template lang="pug">
  v-container(fluid)
    v-row
      v-col(cols="12" sm="3")
        v-card
          v-list(subheader)
            v-subheader {{ $t('management.objects') }}
            v-list-item-group(
              v-model="selectedSettingsObject"
            )
              v-list-item(
                v-for="(settingsObject, i) in settingsObjects"
                :key="i"
                :value="settingsObject"
                link
              )
                v-list-item-content
                  v-list-item-title {{ settingsObject.text }}
                v-list-item-action
                  v-icon mdi-chevron-right
      v-col(cols="12" sm="9")
        template(v-if="!selectedSettingsObject")
          v-card
            v-card-text {{ $t('management.please-select') }}
        template(v-else)
          v-card(
            v-if="selectedSettingsObject.type === settingsObjectTypeOrganization"
            :loading="isLoadingOrganizations"
          )
            v-card-title {{ $tc('organizations.organization', 2) }}
            v-card-text(v-if="!hasOrganizations")
              em {{ $t('organizations.none-found') }}
            v-list(subheader v-else)
              template(
                v-for="organization in getOrganizations"
              )
                v-list-item(
                  :key="`list-item-${organization.id}`"
                )
                  v-list-item-avatar(tile style="overflow: visible;")
                    v-badge(
                      :value="organization.isCurrent()"
                      overlap
                      color="green"
                      right
                      icon="mdi-check"
                    )
                      v-avatar(size="36" :color="organization.color" )
                        v-img(:src="organization.logoSrc" contain)
                  v-list-item-content
                    v-list-item-title {{ organization.name }}
                    v-list-item-subtitle {{ organization.hostnames.join(', ') }}
                  v-list-item-action
                    v-menu(
                      bottom
                      left
                    )
                      template(v-slot:activator="{ on, attrs }")
                        v-btn(
                          icon
                          v-bind="attrs"
                          v-on="on"
                        )
                          v-icon mdi-dots-vertical
                      v-list
                        v-list-item(
                          @click="editOrganization(organization)"
                        )
                          v-list-item-icon
                            v-icon mdi-pencil
                          v-list-item-content
                            v-list-item-title {{ $t('item.edit', { item: organization.name }) }}
                        v-list-item(
                          @click="organizationsManager.deleteObject(organization)"
                        )
                          v-list-item-icon
                            v-icon mdi-delete
                          v-list-item-content
                            v-list-item-title {{ $t('item.delete', { item: organization.name }) }}
                        v-list-item(
                          @click="copyText(organization.id)"
                        )
                          v-list-item-icon
                            v-icon mdi-identifier
                          v-list-item-content
                            v-list-item-title {{ $t('item.copy', { item: "ID" }) }}
                v-divider(
                  :key="`divider-${organization.id}`"
                )
            v-card-actions
              v-spacer
              ManageObject(
                ref="organizationsManager"
                :objectText="$tc('organizations.organization', 1)"
                objectTitleKey="name"
                :defaultObject="defaultOrganization"
                actionAdd="ADD_ORGANIZATION"
                actionUpdate="UPDATE_ORGANIZATION"
                actionDelete="DELETE_ORGANIZATION"
                @reset="colorPicker = {}; uploadLogoFile = null; editLogo = false"
              )
                template(
                  v-slot:form="{ edittingObject }"
                )
                  v-text-field(
                    v-model="edittingObject.name"
                    :label="$t('name')"
                    :rules="rules"
                    required
                  )
                  v-combobox(
                    v-model="edittingObject.hostnames"
                    :label="$t('hostname')"
                    :items="[]"
                    :rules="rules"
                    required
                    multiple
                    chips
                    deletable-chips
                    :delimiters="[' ']"
                  )
                  v-fade-transition(leave-absolute)
                    v-badge.mb-4(
                      :value="hoverLogo"
                      v-if="edittingObject.logo && !editLogo"
                      color="primary"
                      overlap
                      icon="mdi-pencil"
                      bordered
                    )
                      v-hover(v-model="hoverLogo")
                        v-avatar(
                          size="50"
                          :color="edittingObject.color"
                          @click="editLogo = true"
                        )
                          v-img(:src="base64Src(edittingObject.logo)" contain)
                    v-file-input(
                      v-else
                      ref="logoInput"
                      v-model="uploadLogoFile"
                      :label="$t('logo')"
                      prepend-icon="mdi-image-outline"
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                      @change="addLogoToOrganization(edittingObject)"
                      required
                      :rules="rules.concat(fileSize)"
                      append-outer-icon="mdi-close"
                      @click:append-outer="editLogo = false"
                    )
                  v-text-field(
                    v-model="edittingObject.color"
                    :label="$t('color')"
                    :rules="rules"
                    required
                    @change="colorPicker = $event"
                    placeholder="#ABC123"
                  )
                  v-color-picker(
                    v-model="colorPicker"
                    @update:color="edittingObject.color = $event.hex"
                  )
                  GroupsAccess(
                    v-model="edittingObject.groups"
                  )
          v-card(
            v-if="selectedSettingsObject.type === settingsObjectTypeAssetType"
            :loading="isLoadingAssetTypes"
          )
            v-card-title {{ $tc('asset-types.type', 2) }}
            v-card-text(v-if="!hasAssetTypes")
              em {{ $t('asset-types.none-found') }}
            v-list(subheader v-else)
              template(
                v-for="assetType in getAssetTypes"
              )
                v-list-item(
                  :key="`list-item-${assetType.id}`"
                )
                  v-list-item-avatar(color="primary")
                    v-icon(color="white") {{ assetType.icon }}
                  v-list-item-content
                    v-list-item-title {{ assetType.name }}
                  v-list-item-action
                    v-menu(
                      bottom
                      left
                    )
                      template(v-slot:activator="{ on, attrs }")
                        v-btn(
                          icon
                          v-bind="attrs"
                          v-on="on"
                        )
                          v-icon mdi-dots-vertical
                      v-list
                        v-list-item(
                          @click="assetTypesManager.editObject(assetType)"
                        )
                          v-list-item-icon
                            v-icon mdi-pencil
                          v-list-item-content
                            v-list-item-title {{ $t('item.edit', { item: assetType.name }) }}
                        v-list-item(
                          @click="assetTypesManager.deleteObject(assetType)"
                        )
                          v-list-item-icon
                            v-icon mdi-delete
                          v-list-item-content
                            v-list-item-title {{ $t('item.delete', { item: assetType.name }) }}
                        v-list-item(
                          @click="copyText(assetType.id)"
                        )
                          v-list-item-icon
                            v-icon mdi-identifier
                          v-list-item-content
                            v-list-item-title {{ $t('item.copy', { item: 'ID' }) }}
                v-divider(
                  :key="`divider-${assetType.id}`"
                )
            v-card-actions
              v-spacer
              ManageObject(
                ref="assetTypesManager"
                :objectText="$tc('asset-types.type', 1)"
                objectTitleKey="name"
                :defaultObject="defaultAssetType"
                actionAdd="ADD_ASSET_TYPE"
                actionUpdate="UPDATE_ASSET_TYPE"
                actionDelete="DELETE_ASSET_TYPE"
              )
                template(
                  v-slot:form="{ edittingObject }"
                )
                  v-text-field(
                    v-model="edittingObject.name"
                    :label="$t('name')"
                    :rules="rules"
                    required
                  )
                  v-autocomplete(
                    v-model="edittingObject.kpis"
                    :label="$tc('metric', 2)"
                    :items="getSensorTypes"
                    required
                    multiple
                    chips
                    deletable-chips
                    item-value="id"
                    item-text="name"
                    :delimiters="[' ']"
                    :hint="$t('metrics.select-for-detail')"
                    persistent-hint
                    :rules="selectMax3"
                  )
                  v-text-field(
                    v-model="edittingObject.icon"
                    :label="$t('icon')"
                    :rules="rules"
                    required
                    placeholder="mdi-cast-audio-variant"
                    persistent-hint
                    :hint="$t('icons.select-from-mdi')"
                  )
                  GroupsAccess(
                    v-model="edittingObject.groups"
                  )
          v-card(
            v-if="selectedSettingsObject.type === settingsObjectTypeUser"
            :loading="isLoadingUsers"
          )
            v-card-title {{ $tc('users.user', 2) }}
            v-data-table(
              :items="getUsers"
              :headers="headersUsers"
              :loading="isLoadingUsers"
              :no-data-text="$t('users.none-found')"
              :no-results-text="$t('users.none-found')"
            )
              template(v-slot:item.groups="{ item }")
                v-chip-group
                  v-menu(
                    v-model="addGroupMenu[item.id]"
                    :close-on-content-click="false"
                  )
                    template(v-slot:activator="{ on }")
                      v-chip(
                        v-on="on"
                        color="primary"
                      )
                        v-icon(left small) mdi-plus-circle
                        | {{ $tc('item.add') }}
                    v-card(
                      :loading="isLoadingGroupMember(selectedGroup, item.id)"
                    )
                      v-card-text
                        v-autocomplete(
                          v-model="selectedGroup"
                          :label="$tc('groups.group', 1)"
                          :items="getCurrentUser.groups \
                            .filter((g) => !item.groups.map((_g) => _g.value).includes(g.value))"
                          :rules="rules"
                          required
                        )
                      v-divider
                      v-card-actions
                        v-spacer
                        v-btn(
                          :loading="isLoadingGroupMember(selectedGroup, item.id)"
                          :disabled="!selectedGroup"
                          @click="addGroupMember(item.id)"
                          text
                          color="primary"
                        )
                          v-icon(left) mdi-plus
                          | {{ $tc('item.add') }}
                  v-menu(
                    :close-on-content-click="false"
                  )
                    template(v-slot:activator="{ on }")
                      v-hover(v-slot="{ hover }")
                        v-chip(
                          v-on="on"
                        )
                          v-avatar(left color="primary")
                            v-icon(small color="white") mdi-account-group-outline
                          | {{ $tc('groups.group', 2) }}
                          v-avatar(right)
                            v-slide-x-reverse-transition(leave-absolute)
                              v-icon(v-if="hover" small) mdi-pencil
                              strong(v-else) {{ item.groups.length }}
                    v-list
                      v-list-item(
                        v-for="group in item.groups"
                        :key="group.value"
                      )
                        v-list-item-content
                          v-list-item-title {{ group.text }}
                        v-list-item-action
                          v-btn(
                            v-if="item.groups.length > 1"
                            :loading="isLoadingGroupMember(group.value, item.id)"
                            :disabled="isLoadingGroupMember(group.value, item.id)"
                            icon
                            @click="deleteGroupMember(group.value, item.id)"
                          )
                            v-icon mdi-delete
              template(v-slot:item.roles="{ item }")
                v-chip(v-for="(role, i) in item.roles" :key="i")
                  v-icon(left small) mdi-account-cowboy-hat-outline
                  | {{ role }}
            v-card-actions
              v-spacer
              ManageObject(
                ref="usersManager"
                :objectText="$tc('users.user', 1)"
                objectTitleKey="name"
                :defaultObject="defaultUser"
                actionAdd="ADD_USER"
                actionUpdate="UPDATE_USER"
                actionDelete="DELETE_USER"
              )
                template(
                  v-slot:form="{ edittingObject }"
                )
                  v-text-field(
                    v-model="edittingObject.displayName"
                    :label="$t('name')"
                    :rules="rules"
                    required
                  )
                  v-text-field(
                    v-model="edittingObject.mail"
                    :label="$tc('mail-address', 1)"
                    :rules="rules"
                    required
                  )
                  v-text-field(
                    v-model="edittingObject.givenName"
                    :label="$t('users.given-name')"
                    :rules="rules"
                    required
                  )
                  v-text-field(
                    v-model="edittingObject.surname"
                    :label="$t('users.surname')"
                    :rules="rules"
                    required
                  )
                  v-text-field(
                    v-model="edittingObject.companyName"
                    :label="$tc('organizations.organization', 1)"
                    :rules="rules"
                    required
                  )
                  v-text-field(
                    v-model="edittingObject.password"
                    :label="$t('password')"
                    :rules="rules"
                    required
                    type="password"
                  )
                  GroupsAccess(
                    v-model="edittingObject.groups"
                  )
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { base64Src } from '@/helpers/consts';
import ManageObject from '@/components/misc/ManageObject.vue';
import GroupsAccess from '@/components/form/GroupsAccess.vue';

export default Vue.extend({
  name: 'Management',
  components: {
    ManageObject,
    GroupsAccess,
  },
  props: {
    selectedSettingsType: [String, null],
  },
  data() {
    return {
      defaultOrganization: {
        name: '',
        hostnames: [],
        groups: [],
        logo: '',
        color: '',
      },
      defaultAssetType: {
        name: '',
        kpis: [],
        groups: [],
        icon: '',
      },
      defaultUser: {
        displayName: '',
        mail: '',
        givenName: '',
        surname: '',
        companyName: '',
        password: '',
        groups: [],
      },
      uploadLogoFile: null,
      editLogo: false,
      hoverLogo: false,
      rules: [
        (v) => !!v || this.$t('validation.mandatory'),
      ],
      fileSize: [
        (f) => !f || f.size < 512 * 1024 || this.$t('validation.file-size', { s: '512kb' }),
      ],
      selectMax3: [
        (v) => v.length <= 3 || this.$tc('validation.select-max', 2, { n: 3 }),
      ],
      colorPicker: {},
      base64Src,
      selectedGroup: null,
      addGroupMenu: {},
      selectedSettingsObject: null,
      settingsObjectTypeOrganization: 'organization',
      settingsObjectTypeAssetType: 'asset-type',
      settingsObjectTypeUser: 'user',
    };
  },
  mounted() {
    if (this.selectedSettingsType) {
      this.selectedSettingsObject = this.settingsObjects
        .find((s) => this.selectedSettingsType === s.type);
    }
  },
  computed: {
    ...mapGetters([
      'getOrganizations',
      'hasOrganizations',
      'isLoadingOrganizations',
      'getAssetTypes',
      'hasAssetTypes',
      'isLoadingAssetTypes',
      'getSensorTypes',
      'getUsers',
      'getUser',
      'hasUsers',
      'isLoadingUsers',
      'isLoadingGroupMember',
      'getCurrentUser',
      'getAssets',
    ]),
    organizationsManager() {
      return this.$refs.organizationsManager;
    },
    assetTypesManager() {
      return this.$refs.assetTypesManager;
    },
    headersUsers() {
      return [{
        text: this.$t('name'),
        sortable: true,
        value: 'displayName',
      }, {
        text: this.$tc('mail-address', 1),
        sortable: true,
        value: 'mail',
      }, {
        text: this.$tc('groups.group', 2),
        sortable: false,
        value: 'groups',
      }, {
        text: this.$t('users.role'),
        sortable: false,
        value: 'roles',
      }, {
        text: this.$tc('organizations.organization', 1),
        value: 'companyName',
      }];
    },
    settingsObjects() {
      return [{
        text: this.$tc('organizations.organization', 2),
        action: this.loadOrganizations,
        type: this.settingsObjectTypeOrganization,
      }, {
        text: this.$tc('asset-types.type', 2),
        action: this.loadAssetTypes,
        type: this.settingsObjectTypeAssetType,
      }, {
        text: this.$tc('users.user', 2),
        action: this.loadUsers,
        type: this.settingsObjectTypeUser,
      }];
    },
  },
  methods: {
    editOrganization(organization) {
      this.colorPicker = organization.color;
      this.organizationsManager.editObject(organization);
    },
    addLogoToOrganization(edittingObject) {
      this.$refs.logoInput.validate();

      this.$nextTick(() => {
        if (!this.$refs.logoInput.valid) return;
        const organization = edittingObject;
        const reader = new FileReader();
        reader.readAsBinaryString(this.uploadLogoFile);
        reader.onload = (event) => {
          organization.logo = window.btoa(event.target.result);
          this.editLogo = false;
        };
        reader.onerror = () => {
          console.log("couldn't read the file");
        };
      });
    },
    deleteGroupMember(groupId, userId) {
      this.$store.dispatch('DELETE_GROUP_MEMBER', {
        groupId,
        userId,
      });
    },
    addGroupMember(userId) {
      this.$store.dispatch('ADD_GROUP_MEMBER', {
        groupId: this.selectedGroup,
        userId,
      }).then(() => {
        this.addGroupMenu[userId] = false;
      });
    },
    loadOrganizations() {
      this.$store.dispatch('GET_ORGANIZATIONS');
    },
    loadAssetTypes() {
      this.$store.dispatch('GET_ASSET_TYPES');
      this.$store.dispatch('GET_SENSOR_TYPES');
    },
    loadUsers() {
      this.$store.dispatch('GET_USERS');
    },
  },
  watch: {
    selectedSettingsObject(o) {
      if (o) {
        this.$router.push({ name: 'management', params: { type: o.type } }).catch(() => {});
        o.action();
      } else {
        this.$router.push({ name: 'management' }).catch(() => {});
      }
    },
  },
});
</script>
